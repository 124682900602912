export const blogData = [
    // {
    //     id: 1,
    //     title: 'SCRAPING BILLBOARD AND IMDB USING PYTHON SELENIUM',
    //     description: 'Here we will use Selenium to navigate between web pages, and try to scrape data from them.',
    //     date: 'Aug 7, 2023',
    //     image: 'https://1.bp.blogspot.com/-6vZQoWcIZeo/Xvw5ZD5Y53I/AAAAAAAABgI/GH2b5OuMaXAKuigK4diBbeYDW6dD3qVvgCK4BGAsYHg/w400-h225/selenium.jpg',
    //     url: 'https://hackzism.blogspot.com/2020/07/scraping-billboard-and-imdb-using.html'
    // },
    // {
    //     id: 2,
    //     title: 'CHECK WEATHER FROM TERMINAL USING WTTR.IN',
    //     description: 'wttr.in is a console-oriented weather forecast service that supports various information representation methods like ANSI-sequences for console HTTP clients, HTML, or PNG.',
    //     date: 'Aug 11, 2020',
    //     image: 'https://1.bp.blogspot.com/-OW7jX57tea4/XvnGxuEOslI/AAAAAAAABW0/R8lVT1AXDSwnvE0EGA9Ra49-LDm1ACwDgCK4BGAsYHg/s1216/wttr1.png',
    //     url: 'https://hackzism.blogspot.com/2020/06/check-weather-from-terminal-using-wttrin.html'
    // },
    {
        id: 3,
        title: 'BEING ANONYMOUS: A BEGINNERS GUIDE',
        description: 'Here are some basic ways to lay off the Radar',
        date: 'Aug 14, 2023',
        image: 'https://th.bing.com/th/id/OIP.UCEvVIXfFt_htlBJAUwaaQHaHa?pid=ImgDet&rs=1',
        url: 'https://www.linkedin.com/pulse/being-anonymous-beginners-guide-rajiv-ranjan'
    },
    {
        id: 4,
        title: 'CHECK INTERNET SPEED FROM THE COMMAND LINE',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2023',
        image: 'https://assets-global.website-files.com/5fa5aa23940bc0fde48100dc/5fe268050d56a47f7ce09a52_speedtestpic.jpg',
        // image: 'https://www.socket.net/sites/default/files/inline-images/speed_test.png',
        url: 'https://www.linkedin.com/pulse/check-internet-speed-from-command-line-rajiv-ranjan'
        // url: 'https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html'
    },
    {
        id: 5,
        title: 'Create & Deplyo a Flask App',
        description: 'How to Build a web application using Flask and deploy it to the cloud',
        date: 'Aug 21, 2023',
        image: 'https://th.bing.com/th/id/OIP.j9k-vR1rFck-pQ1ifWUA8QHaEG?w=284&h=180&c=7&r=0&o=5&dpr=2&pid=1.7',
        url: 'https://www.linkedin.com/pulse/how-build-web-application-using-flask-deploy-cloud-rajiv-ranjan'
    },
        {
        id: 6,
        title: 'Host a Python Flask app on your NAS using Docker',
        description: 'How to host a Python Flask app on your NAS using Docker in very easy steps',
        date: 'May 20, 2024',
        image: 'https://miro.medium.com/v2/resize:fit:395/1*oYkwTRZQuKsMpemysNDEig.jpeg',
        url: 'https://www.linkedin.com/pulse/how-host-python-flask-project-your-nas-docker-rajiv-ranjan-tystc'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/
